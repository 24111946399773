export const equipments = [
    {key: 'Boxing', label: 'Boxing'}, 
    {key: 'Unboxing', label: 'Unboxing'}, 
    {key: 'Furniture Disassembly', label: 'Furniture Disassembly'},
    {key: 'Furniture Assembly', label: 'Furniture Assembly'}, 
    {key: 'Post-Move Cleaning (of the residence that the customer is moving from)', label: 'Post-Move Cleaning (of the residence that the customer is moving from)'}, 
    {key: 'Transport', label: 'Transport'},
    {key: 'Storage', label: 'Storage'},
    {key: 'End-to-end video recording of service provided', label: 'End-to-end video recording of service provided'},
];

export const insuranceAmount = [
    {key: 'insurance-1', label: 'Insurance1', value: '10000'},
    {key: 'insurance-2', label: 'Insurance2', value: '30000'},
    {key: 'insurance-3', label: 'Insurance3', value: '50000'},
];

export const businessStructure = [
    {key: 'sole_proprietorship', label: 'Sole Proprietorship', value: 'sole proprietorship'},
    {key: 'partnership', label: 'Partnership', value: 'partnership'},
    {key: 'limited_liability_company', label: 'Limited Liability Company', value: 'limited liability company'},
    {key: 'corporation_c_corp', label: 'Corporation – C Corp', value: 'corporation-c corp'},
    {key: 's_corp', label: 'S Corp', value: 's corp'},
    {key: 'benefit_corporation', label: 'Benefit Corporation', value: 'benefit corporation'},
    {key: 'close_corporation', label: 'Close Corporation', value: 'close corporation'},
    {key: 'non_profit_corporation', label: 'Non-Profit Corporation', value: 'non-profit corporation'},
];

export const cityAvailable = [
    {
        id: 'new-york-city',
        link: '/s?address=New%20York%20City%2C%20New%20York%2C%20United%20States&bounds=41.611064477071494%2C-72.8208651356227%2C39.8144339229285%2C-75.1911238643773'
    },
    {
        id: 'los-angeles',
        link: '/s?address=Los%20Angeles%2C%20California%2C%20United%20States&bounds=34.9520062770715%2C-117.15851618988114%2C33.155375722928504%2C-119.32701581011887'
    },
    {
        id: 'chicago',
        link: '/s?address=Chicago%2C%20Illinois%2C%20United%20States&bounds=42.7738772770715%2C-86.4179749740077%2C40.977246722928506%2C-88.83086702599229'
    },
    {
        id: 'houston',
        link: '/s?address=Houston%2C%20Texas%2C%20United%20States&bounds=30.657253277071497%2C-94.33291628631765%2C28.860622722928504%2C-96.40247771368236'
    },
    {
        id: 'phoenix',
        link: '/s?address=Phoenix%2C%20Arizona%2C%20United%20States&bounds=34.346752277071495%2C-110.99751874442795%2C32.5501217229285%2C-113.15076525557204'
    },
    {
        id: 'philadelphia',
        link: '/s?address=Philadelphia%2C%20Pennsylvania%2C%20United%20States&bounds=40.8510392770715%2C-73.9916696360081%2C39.05440872292851%2C-76.33538236399191'
    },
    {
        id: 'san-diego',
        link: '/s?address=San%20Diego%2C%20California%2C%20United%20States&bounds=33.615735277071494%2C-116.09506155487156%2C31.8191047229285%2C-118.23048444512844'
    },
    {
        id: 'san-francisco',
        link: '/s?address=San%20Francisco%2C%20California%2C%20United%20States&bounds=38.6775528770715%2C-121.28279352839883%2C36.880922322928505%2C-123.55592447160117'
    },
    {
        id: 'miami',
        link: '/s?address=Miami%2C%20Florida%2C%20United%20States&bounds=26.672488277071498%2C-79.19606259189759%2C24.875857722928505%2C-81.1911774081024'
    },
    {
        id: 'dallas',
        link: '/s?address=Dallas%2C%20Texas%2C%20United%20States&bounds=33.674587277071495%2C-95.72843897840475%2C31.877956722928502%2C-97.86527302159526'
    }
];

export const IN_THE_NEWS = "news-center";
export const BLOG = "blog";
export const CONTACT_US = "contact-us";